@use "./../VCX/base";

:root {
  --ribbon-size: 1; //this.control.Parent.Parent.Size
}

.rb_isection_base {
  border-width: 0px;
  border-right-width: calc(var(--GridControl-LineWidth) * 1px);
  border-style: solid;
  border-color: var(--ColorMap-ContentFrame1);
  flex-direction: column;
  padding: 0 calc(var(--Zoom) * 3px);
}

.rb_isection_name {
  width: 100%;
  justify-content: center;
  border-width: 0px;
  border-top-width: calc(var(--GridControl-LineWidth) * 1px);
  border-style: solid;
  border-color: var(--ColorMap-ContentFrame1);
  color: var(--ColorMap-DataBrowseColorFrg);
  @media only screen and (max-width: 980px) {
    display: none;
  }
}

.rb_isection_font {
  @include base.label_font;
}

.rb_isection_subsection_base {
  height: calc(calc(25px * var(--Zoom)) * var(--ribbon-size));
  display: grid;
  grid-template-rows: repeat(var(--ribbon-size), 1fr);
  grid-auto-flow: column;
  @media only screen and (max-width: 980px) {
    display: flex;
    height: calc(calc(25px * var(--Zoom)) * 1.2); // 16 mmísto LabelControl.GetHeight(1)
    gap: calc(var(--Zoom) * 3px);
  }
}

.rb_toolbar_base {
  width: 100%;
  justify-content: space-between;
  background: var(--ColorMap-DataBrowseColorBck);
  padding: calc(var(--MarginY) * 1px) calc(var(--MarginX) * 1px);
  @media only screen and (max-width: 980px) {
    boxshadow: "inset 5px 0px 10px -10px rgb(0 0 0 / 50%), inset -5px 0px 10px -10px rgb(0 0 0 / 50%)";
  }
}

.rb_toolbar_menu {
  color: var(--ColorMap-ContentDecorateColorFrg);
  align-self: center;
  @media only screen and (max-width: 980px) {
    display: initial;
  }
  > div {
    justify-content: flex-end;
  }
}

.rb_toolbar_hideBtn {
  color: var(--ColorMap-ContentDecorateColorFrg);
  align-self: flex-end;
  @media only screen and (max-width: 980px) {
    display: none;
  }
}

.rb_toolbar_content {
  overflow-x: auto;
  flex: 1 1 auto;
}

.rb_toolbar_buttons {
  flex-shrink: 0;
}

.rb_accestor_page {
  background-color: var(--ColorMap-BaseColorBck1);
  color: var(--ColorMap-BaseColorFrg1);
  align-self: center;
  height: 100%;
  align-items: center;
  padding: 5px 10px;
}

.rb_accestor_page_active {
  background-color: var(--ColorMap-DataBrowseColorBck);
  color: var(--ColorMap-DataBrowseColorFrg);
}

.rb_accestor_page_font {
  @include base.label_font;
}

.rb_accestor_page_text {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: calc(10px * var(--Zoom));
}

.rb_base {
  flex-direction: column;
  height: 100%;
  @media only screen and (max-width: 980px) {
    flex-direction: row-reverse;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  }
}

.rb_header {
  background: var(--ColorMap-BaseColorBck1);
  padding: 3px 3px 0px 3px;
  border-left: none;
  flex: 0 0 auto;
  @media only screen and (max-width: 980px) {
    height: 100%;
    background: var(--ColorMap-DataBrowseColorBck);
    border-width: calc(var(--GridControl-LineWidth) * 1px);
    border-style: solid;
    border-color: var(--ColorMap-ContentFrame1);
  }
}

.rb_pages {
  flex: 1 1 auto;
  overflow-x: hidden;
  gap: 6px;
  @media only screen and (max-width: 980px) {
    display: none;
  }
}

.rb_menu {
  flex: none;
  color: var(--ColorMap-BaseColorFrg1);
  > div {
    justify-content: flex-end;
    @media only screen and (max-width: 980px) {
      justify-content: center;
      color: var(--ColorMap-ContentDecorateColorFrg);
    }
  }
  @media only screen and (max-width: 980px) {
    flex: 1 0 100%;
    justify-content: flex-end;
    align-items: center;
  }
}

.rb_toolbar {
  border-width: calc(var(--GridControl-LineWidth) * 1px);
  border-style: solid;
  border-color: var(--ColorMap-ContentFrame1);
  @media only screen and (max-width: 980px) {
    border-right-width: 0px;
    flex: 1 1 auto;
  }
}
.rb_toolbar_multi {
  border-top-width: 0px;
  @media only screen and (max-width: 980px) {
    border-top-width: calc(var(--GridControl-LineWidth) * 1px);
  }
}
