.wv_object {
  width: 100%;
  height: 100%;

  &.wv_iframe {
    border: none;
  }
}
.wv {
  position: relative;
}
